import { render, staticRenderFns } from "./hkFlash.vue?vue&type=template&id=24fc55bc&scoped=true&"
import script from "./hkFlash.vue?vue&type=script&lang=js&"
export * from "./hkFlash.vue?vue&type=script&lang=js&"
import style0 from "./hkFlash.vue?vue&type=style&index=0&id=24fc55bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24fc55bc",
  null
  
)

export default component.exports